@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
.font-sans {
  font-family: "kern", sans-serif;
}
.font-serif {
  font-family: "kern", sans-serif;
}

body {
  @apply font-sans font-normal text-neutral-800 md:text-sm lg:text-lg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "kern", sans-serif;
  line-height: 1.2em !important;
}
h1 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4;
}
h1.heading {
  @apply text-5xl md:text-7xl lg:text-8xl mb-4;
}
h2 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h3 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-4;
}
